var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-table',{staticClass:"text-center",attrs:{"items":_vm.data,"responsive":"","fields":[
      'nsu',
      { key: 'transactionDate', label: 'Data da Transação'},
      { key: 'saleId', label: 'ID da Venda'},
      { key: 'installmentId', label: 'ID da Parcela'},
      { key: 'installmentNumber', label: 'Número da Parcela' },
      { key: 'amountInstallment', label: 'valor da parcela'},
      { key: 'amountTransaction', label: 'Valor da Transação' },
      { key: 'user', label: 'Usuário' },
      { key: 'status', label: 'status' }
    ]},scopedSlots:_vm._u([{key:"cell(transactionDate)",fn:function(data){return [_vm._v(" "+_vm._s(_vm._f("date")(data.item.transactionDate))+" ")]}},{key:"cell(status)",fn:function(data){return [_vm._v(" "+_vm._s(_vm.status[data.item.status])+" ")]}},{key:"cell(amountInstallment)",fn:function(data){return [_vm._v(" "+_vm._s(_vm._f("value")(data.item.amountInstallment))+" ")]}},{key:"cell(amountTransaction)",fn:function(data){return [_vm._v(" "+_vm._s(_vm._f("value")(data.item.amountTransaction))+" ")]}}])}),_c('b-pagination',{staticClass:"my-2",attrs:{"per-page":10,"total-rows":_vm.totalItems,"align":"center","size":"sm"},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }