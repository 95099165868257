<template>
  <div>
    <b-table
      :items="data"
      class="text-center"
      responsive
      :fields="[
        'nsu',
        { key: 'transactionDate', label: 'Data da Transação'},
        { key: 'saleId', label: 'ID da Venda'},
        { key: 'installmentId', label: 'ID da Parcela'},
        { key: 'installmentNumber', label: 'Número da Parcela' },
        { key: 'amountInstallment', label: 'valor da parcela'},
        { key: 'amountTransaction', label: 'Valor da Transação' },
        { key: 'user', label: 'Usuário' },
        { key: 'status', label: 'status' }
      ]"
    >
      <template #cell(transactionDate)="data">
        {{ data.item.transactionDate | date }}
      </template>

      <template #cell(status)="data">
        {{ status[data.item.status] }}
      </template>

      <template #cell(amountInstallment)="data">
        {{ data.item.amountInstallment | value }}
      </template>

      <template #cell(amountTransaction)="data">
        {{ data.item.amountTransaction | value }}
      </template>
    </b-table>
    <b-pagination
      v-model="page"
      :per-page="10"
      :total-rows="totalItems"
      align="center"
      size="sm"
      class="my-2"
    />
  </div>
</template>

<script>
import { BTable, BPagination } from 'bootstrap-vue';

import InstallmentsService from '../../../service/installments';

export default {
    components: { BTable, BPagination },

    data() {
        return {
            page: 1,
            totalItems: 1,
            data: [],
            status: {
                Authorized: 'Autorizado',
                Refunded: 'Cancelado',
            },
        };
    },

    watch: {
        async page() {
            this.callLoading(true);
            const { data: { show } } = await InstallmentsService.getHistory({
                page: this.page,
                limit: 10,
            });
            this.data = show.data;
            this.callLoading(false);
        },
    },

    async mounted() {
        const { data: { show } } = await InstallmentsService.getHistory({
            page: 1,
            limit: 10,
        });

        this.totalItems = show.meta.total;
        this.data = show.data;
    },
};
</script>
