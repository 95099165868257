<template>
  <b-container>
    <b-card>
      <b-form>
        <b-row>
          <b-col md="6">
            <b-form-group
              label-for="nsu"
              label="NSUs"
            >
              <BFormTags
                id="nsu"
                v-model="nsus"
                disable-add-button
                placeholder="Adicione Nsu"
              />
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group
              label-for="date"
              label="Data da Transação"
            >
              <b-form-input
                id="date"
                v-model="date"
                type="date"
              />
            </b-form-group>
          </b-col>

          <b-button
            class="mx-auto"
            variant="primary"
            :disabled="!nsus.length || !date"
            @click="consult"
          >
            Consultar
          </b-button>
        </b-row>
      </b-form>
    </b-card>

    <b-card v-if="transactions.length > 1">
      <h3> Transações </h3>
      <b-table
        class="text-center"
        :items="transactions"
        hover
        :fields="[
          { key: 'nsu', label: 'NSU' },
          { key: 'transactionDate', label: 'Data da Transação' },
          { key: 'amountTransaction', label: 'valor' },
        ]"
        @row-clicked="showModal"
      >
        <template #cell(transactionDate)="data">
          {{ data.item.transactionDate | date }}
        </template>

        <template #cell(amountTransaction)="data">
          {{ data.item.amountTransaction | value }}
        </template>
      </b-table>
    </b-card>

    <b-card v-if="installments.length">
      <h3> Parcelas </h3>
      <installment-table
        :items="installments"
        @saved="updateStatus"
      />
    </b-card>

    <b-card>
      <h3 class="mb-1">
        Histórico
      </h3>
      <History />
    </b-card>

    <b-modal
      ref="modal"
      hide-footer
      size="xl"
    >
      <installment-table
        :items="installments.filter(item => item.nsu === nsu)"
        @saved="updateStatus"
      />
    </b-modal>
  </b-container>
</template>

<script>
import {
    BContainer, BFormGroup, BForm, BFormInput, BFormTags, BCard, BRow, BCol, BButton, BTable, BModal,
} from 'bootstrap-vue';

import InstallmentTable from './InstallmentTable.vue';

import InstallmentService from '@/service/installments';

import History from './History.vue';

export default {
    components: {
        BContainer, BFormGroup, BForm, BFormInput, BFormTags, BCard, BRow, BCol, BButton, BTable, BModal, InstallmentTable, History,
    },

    data() {
        return {
            nsus: [],
            nsu: '',
            date: null,
            installments: [],
        };
    },

    computed: {
        transactions() {
            const map = new Map(this.installments.map(item => [item.nsu, { transactionDate: item.transactionDate, nsu: item.nsu, amountTransaction: item.amountTransaction }]));
            return Array.from(map.values());
        },
    },

    methods: {
        async consult() {
            this.callLoading(true);
            const { date, nsus } = this;
            const { data } = await InstallmentService.consultNsus({ date, nsus });
            if (!data.data.length) {
                this.modalError('Não possui transações com esses nsus');
            } else {
                this.installments = data.data;
            }
            this.callLoading(false);
        },

        showModal(data) {
            this.nsu = data.nsu;
            this.$refs.modal.show();
        },

        async updateStatus({ ids: installmentsIds, status }) {
            const response = await this.confirmAnAction('Deseja realmente trocar o status dessas parcelas?');

            if (!response) return;

            this.callLoading(true);
            const { status: statusApi } = await InstallmentService.updateStatus({ installmentsIds, status, username: this.$store.state.user.data.name });
            if (statusApi === 200) {
                this.modalSuccess('Status Atualizado com Sucesso');

                // eslint-disable-next-line no-restricted-syntax
                this.installments = this.installments.filter(item => !installmentsIds.includes(item.installmentId));

                if (!this.installments.length) {
                    this.nsus = [];
                }
            } else {
                this.modalError('Não foi possível trocar o status');
            }
            this.callLoading(false);
        },
    },
};
</script>
